export const SET_ROLE = (role) => {
    return {
        type: "SET_ROLE",
        role: role,
    };
};

export const SET_ROLE_NULL = () => {
    return {
        type: "SET_ROLE_NULL",
    };
};